
<template>
  <div>
    <div class="text-center font-weight-bold pt-2">Status do curso: {{course_status_map[selected_course.status]}}</div>
    <div v-if="!short_description">
      <div v-if="!no_course_name" class="pa-0 px-1 font-weight-bold pt-2 primary--text">Nome do curso:</div>
      <div v-if="!no_course_name" class="pa-0 px-3">{{selected_course.name}}</div>
      <div class="pa-0 px-1 font-weight-bold pt-2 primary--text">Coordenador:</div>
      <div class="pa-0 px-3">{{selected_course.coordinator_name}}</div>
      <div class="pa-0 px-1 font-weight-bold pt-2 primary--text">Email:</div>
      <div class="pa-0 px-3">{{selected_course.coordinator_email}}</div>
      <div class="pa-0 px-1 font-weight-bold pt-2 primary--text">Telefone:</div>
      <div class="pa-0 px-3">{{selected_course.coordinator_phone}}</div>
      <div class="pa-0 px-1 font-weight-bold pt-2 primary--text">Instituição de ensino</div>
      <div class="pa-0 px-3">{{selected_course.school}}</div>
      <div class="pa-0 px-1 font-weight-bold pt-2 primary--text">Local:</div>
      <div class="pa-0 px-3">{{selected_course.place}}</div>
      <div class="pa-0 px-1 font-weight-bold pt-2 primary--text">Requisitos: </div>
      <div class="pa-0 px-3">{{selected_course.requirements}}</div>
      <div class="pa-0 px-1 font-weight-bold pt-2 primary--text">Início: </div>
      <div class="pa-0 px-3">{{this.$dayjs(selected_course.start_date).format('LL')}}</div>
      <div class="pa-0 px-1 font-weight-bold pt-2 primary--text">Término: </div>
      <div class="pa-0 px-3">{{this.$dayjs(selected_course.end_date).format('LL')}}</div>
      <div class="pa-0 px-1 font-weight-bold pt-2 primary--text">Término das inscrições: </div>
      <div class="pa-0 px-3">{{this.$dayjs(selected_course.register_end_date).endOf('day').format('LLLL')}}</div>
      <div class="pa-0 px-1 font-weight-bold pt-2 primary--text">Tipo de curso:</div>
      <div class="pa-0 px-3">{{selected_course.kind == 'distance' ? 'A Distância' : 'Presencial'}}</div>
      <div class="pa-0 px-1 font-weight-bold pt-2 primary--text">Vagas: </div>
      <div class="pa-0 px-3">{{selected_course.total_chairs}}</div>
      <div class="pa-0 px-1 font-weight-bold pt-2 primary--text" v-if="selected_course.classes_info">Informações adicionais: </div>
      <div class="pa-0 px-3" v-if="selected_course.classes_info">{{selected_course.classes_info}}</div>
      <div class="pa-0 px-1 font-weight-bold pt-2 primary--text">Carga horaria total</div>
      <div class="pa-0 px-3">{{selected_course.classes_load}}</div>
      <div class="pa-0 px-1 font-weight-bold pt-2 primary--text">Descrição da carga horaria</div>
      <div class="pa-0 px-3">{{selected_course.classes_load_info}}</div>
    </div>

    <div v-if="short_description">
      <div class="pa-0 px-1 font-weight-bold pt-2 primary--text">Coordenador:</div>
      <div class="pa-0 px-3 ">{{selected_course.coordinator_name}}</div>        
      <div class="pa-0 px-1 font-weight-bold pt-2 primary--text">Início: </div>
      <div class="pa-0 px-3 ">{{this.$dayjs(selected_course.start_date).format('LLLL')}}</div>        
      <div class="pa-0 px-1 font-weight-bold pt-2 primary--text">Termino: </div>
      <div class="pa-0 px-3 ">{{this.$dayjs(selected_course.end_date).format('LLLL')}}</div>        
      <div class="pa-0 px-1 font-weight-bold pt-2 primary--text">Termino das incrições: </div>
      <div class="pa-0 px-3 ">{{this.$dayjs(selected_course.register_end_date).format('LLLL')}}</div>
      <div class="pa-0 px-1 font-weight-bold pt-2 primary--text">Tipo de curso:</div>
      <div class="pa-0 px-3 ">{{selected_course.kind == 'distance' ? 'A Distância' : 'Presencial'}}</div>
    </div>

  </div>
</template>

<script>

export default {
  name: 'CourseDetails',
  props: {
    selected_course: {
      type: Object,
      default: null
    },
    no_course_name:{
      type: Boolean,
      default: false
    },
    short_description:{
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      course_status_map:{
        active: 'Ativo', 
        selecting: 'Selecionando', 
        done_selection: 'Seleção Pronta', 
        in_progress: 'Em Progresso', 
        done: 'Concluído', 
        suspended: 'Cancelado'
      },
    }
  },
}
</script>

<style scoped>

</style>